<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle>
            <span class="anyRating-main-title-titleWord">Evaluation Management / Share Evaluation</span>
          </mainTitle>
          
          <el-card class="edp-backend-conditionSearch" body-style="width: 100%;">
            <div slot="header" class="clearfix fac lh15">
              <span @click="backQuestionList" class="mr4 pot fac">
                <img src="@/src/assets/common/back.png" width="20px" height="21px" />
              </span>
              <strong>{{$t('questionnaire.shareEvaluation')}}</strong>
            </div>
            <el-form :model="shareData" ref="questionForm" label-position="left" label-suffix=":">
              <el-form-item :label="$t('questionnaire.quesName')" prop="name" label-width="180px">
                <span class="color-teal">{{ shareData.name ? shareData.name : '--' }}</span>
              </el-form-item>
              <el-form-item
                label="ADFS URL"
                prop="adfsGenerateUrl"
                label-width="180px"
                v-if="shareData.adfsGenerateUrl"
              >
                <section class="fjs">
                  <span class="mr4">
                    <el-input v-model="shareData.adfsGenerateUrl" type="textarea" readonly style="width: 500px" />
                  </span>
                  <section @click="urlCopy(`${shareData.adfsGenerateUrl}`)" class="fjs pot">
                    <span class="fjc mr4">
                      <img src="@/src/assets/common/copy.png" width="17px" height="17px" />
                    </span>
                    <span class="color-3E886D">{{$t('edpBackendCommon.copy')}}</span>
                  </section>
                </section>
              </el-form-item>
              <el-form-item 
                label="ADFS QR Code"
                prop="adfsQrcode"
                label-width="180px"
                v-if="shareData.adfsQrcode"
              >
                <section class="fae">
                  <span class="mr4 lh-normal">
                    <img :src="shareData.adfsQrcode" v-if="shareData.adfsQrcode" width="80" height="80">
                  </span>
                  <span class="lh15 color-3E886D">
                    <a
                      :href="shareData.adfsQrcode"
                      target="_blank"
                      download="qrcode"
                      class="fjs"
                    >
                      <span class="fjc mr4 mb4">
                        <img src="@/src/assets/common/download.png" width="17px" height="17px" />
                      </span>
                      <span class="fjc mr4 lh-nomarl">{{$t('edpBackendCommon.download')}}</span>
                    </a>
                  </span>
                </section>
              </el-form-item>
              <el-form-item
                label="Employee APP URL"
                prop="appGenerateUrl"
                label-width="180px"
                v-if="shareData.appGenerateUrl"
              >
                <section class="fjs">
                  <span class="mr4">
                    <el-input v-model="shareData.appGenerateUrl" type="textarea" readonly style="width: 500px" />
                  </span>
                  <section @click="urlCopy(`${shareData.appGenerateUrl}`)" class="fjs pot">
                    <span class="fjc mr4">
                      <img src="@/src/assets/common/copy.png" width="17px" height="17px" />
                    </span>
                    <span class="color-3E886D">{{$t('edpBackendCommon.copy')}}</span>
                  </section>
                </section>
              </el-form-item>
              <el-form-item
                label="Employee APP QR Code"
                prop="appQrcode"
                label-width="180px"
                v-if="shareData.appQrcode"
              >
                <section class="fae">
                  <span class="mr4 lh-normal">
                    <img :src="shareData.appQrcode" v-if="shareData.appQrcode" width="80" height="80">
                  </span>
                  <span class="lh15 color-3E886D">
                    <a
                      :href="shareData.appQrcode"
                      target="_blank"
                      download="qrcode"
                      class="fjs"
                    >
                      <span class="fjc mr4 mb4">
                        <img src="@/src/assets/common/download.png" width="17px" height="17px" />
                      </span>
                      <span class="fjc mr4 lh-nomarl">{{$t('edpBackendCommon.download')}}</span>
                    </a>
                  </span>
                </section>
              </el-form-item>
              <el-form-item
                label="H5 URL"
                prop="h5GenerateUrl"
                label-width="180px"
                v-if="shareData.h5GenerateUrl"
              >
                <section class="fjs">
                  <span class="mr4">
                    <el-input v-model="shareData.h5GenerateUrl" type="textarea" readonly style="width: 500px" />
                  </span>
                  <section @click="urlCopy(`${shareData.h5GenerateUrl}`)" class="fjs pot">
                    <span class="fjc mr4">
                      <img src="@/src/assets/common/copy.png" width="17px" height="17px" />
                    </span>
                    <span class="color-3E886D">{{$t('edpBackendCommon.copy')}}</span>
                  </section>
                </section>
              </el-form-item>
              <el-form-item
                label="H5 QR Code"
                prop="h5Qrcode"
                label-width="180px"
                v-if="shareData.h5Qrcode"
              >
                <section class="fae">
                  <span class="mr4 lh-normal">
                    <img :src="shareData.h5Qrcode" v-if="shareData.h5Qrcode" width="80" height="80">
                  </span>
                  <span class="lh15 color-3E886D">
                    <a
                      :href="shareData.h5Qrcode"
                      target="_blank"
                      download="qrcode"
                      class="fjs"
                    >
                      <span class="fjc mr4 mb4">
                        <img src="@/src/assets/common/download.png" width="17px" height="17px" />
                      </span>
                      <span class="fjc mr4 lh-nomarl">{{$t('edpBackendCommon.download')}}</span>
                    </a>
                  </span>
                </section>
              </el-form-item>
              <el-form-item label="" prop="" v-if="shareData.appGenerateUrl && shareData.appQrcode">
                <p class="lh15">
                  <span style="color: #b24747;">*</span>
                  <span style="color: #979797;">{{$t('questionnaire.EmployeeAPPURLTips')}}</span>
                </p>
                <p class="lh15">
                  <span style="color: #b24747;">*</span>
                  <span style="color: #979797;">{{$t('questionnaire.EmployeeAPPQRCode')}}</span>
                </p>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="edp-backend-conditionSearch" body-style="width: 100%;">
            <div slot="header" class="clearfix fac lh15">
              <strong style="margin-right: 20px;">{{$t('questionnaire.shareRecords')}}</strong>
              <el-button type="primary" @click="openShareDialog" style="margin-right: 20px;">{{$t('questionnaire.Gotoshare')}}</el-button>
              <span @click="shareExport" class="ml4 pot fac">
                <img class="mr4" src="@/src/assets/common/export.png" width="15px" height="16px" />
                {{$t('edpBackendCommon.export')}}
              </span>
            </div>
            <section class="edp-backend-conditionSearch" style="margin: 0; padding: 0; box-shadow: 0 0 0 #fff;">
              <div class="edp-backend-conditionSearch-square" style="padding-left: 0;">
                <div class="edp-backend-conditionSearch-label" style="width: 205px;">{{$t('questionnaire.Department/EmployeeName')}}</div>

                <div class="edp-backend-conditionSearch-content">
                  <el-input
                    v-model="searchParam.employee"
                    :placeholder="`${$t('edpBackendCommon.FilterBy')} ${$t('questionnaire.Department/EmployeeName')}`"
                    clearable
                    @change="fetchShareRecordList"
                  />
                </div>
              </div>

              <div class="edp-backend-conditionSearch-square">
                <div class="edp-backend-conditionSearch-label">{{$t('questionnaire.TouchPoint')}}</div>

                <div class="edp-backend-conditionSearch-content">
                  <el-select
                    v-model="searchParam.touchPoint"
                    clearable
                    :placeholder="`${$t('edpBackendCommon.select')} ${$t('questionnaire.TouchPoint')}`"
                  >
                    <el-option
                      :key="1"
                      label="E-Mail"
                      :value="1"
                    />
                    <el-option
                      :key="2"
                      label="Employee APP"
                      :value="2"
                    />
                  </el-select>
                </div>
              </div>
              <div
                class="edp-backend-conditionSearch-square fje"
              >
                <el-button size="small" type="transparent" @click="clearSearch">{{
                  $t("edpBackendCommon.clear")
                }}</el-button>
                <el-button size="small" type="primary" @click="fetchShareRecordList">{{
                  $t("edpBackendCommon.search")
                }}</el-button>
              </div>
            </section>

            <section class="edp-backend-common-table" style="margin: 0; padding: 0; box-shadow: 0 0 0 #fff;">
              <el-table :data="shareRecordData" style="width: 100%">
                <el-table-column prop="createTime" :label="$t('questionnaire.ShareTime')">
                  <template slot-scope="scope">
                    {{ utils.dateFormatFn(scope.row.createTime, 'yyyy.MM.dd HH:mm:ss') }}
                  </template>
                </el-table-column>
                <el-table-column prop="touchPoint" :label="$t('questionnaire.TouchPoint')">
                  <template slot-scope="scope">
                    {{ scope.row.touchPoint === 1 ? 'E-Mail' : scope.row.touchPoint === 2 ? 'Employee APP' : scope.row.touchPoint }}
                  </template>
                </el-table-column>
                <el-table-column prop="deptName" :label="$t('questionnaire.Department')"/>
                <el-table-column prop="name" :label="$t('questionnaire.Employee')"/>
                <el-table-column :label="$t('edpBackendCommon.operate')" fixed="right">
                  <template slot-scope="scope">
                    <el-button  type="text" @click.native="reshare(scope.row)"
                      >{{$t('questionnaire.Reshare')}}</el-button
                    >
                  </template>
                </el-table-column>
                <template slot="empty">
                  <div class="table-empty fcc">
                    <span class="img"><img src="@/src/assets/common/table-empty.png" width="231" height="170" /></span>
                    <span class="text">{{ $t('edpBackendCommon.empty') }}</span>
                  </div>
                </template>
              </el-table>
              <div class="table-footer fjsb">
                <span class="table-pagination-text">{{ total }} {{$t('edpBackendCommon.totalRecords')}}, {{ totalPage }}  {{$t('edpBackendCommon.pages')}}</span>
                <el-pagination
                  :total="total"
                  :current-page="pageNo"
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  @current-change="currentChange"
                />
              </div>
              <div class="fje">
                <el-button @click="backQuestionList">{{ $t('edpBackendCommon.return') }}</el-button>
              </div>
            </section>
          </el-card>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      :title="$t('questionnaire.share')"
      width="1090px"
      :visible.sync="shareDialog.visible"
    >
      <div class="edp-backend-userPermitDialog">
        <section class="edp-backend-userPermitDialog-section">
          <el-form :model="shareDialog" :rules="shareDialog.rules" ref="shareDialogForm" label-position="left" label-suffix=":">
            <el-form-item
               :label="$t('questionnaire.TouchPoint')"
               prop="touchPoints"
               style="margin-bottom: 4px;"
             >
              <el-checkbox-group v-model="shareDialog.touchPoints">
                <el-checkbox :label="1" name="touchPoints" v-if="(shareData.h5GenerateUrl && shareData.h5Qrcode) || (shareData.adfsGenerateUrl && shareData.adfsQrcode)">E-Mail</el-checkbox>
                <el-checkbox
                  :label="2"
                  name="touchPoints"
                  v-if="(shareData.h5GenerateUrl && shareData.h5Qrcode) || (shareData.appGenerateUrl && shareData.appQrcode)"
                >Employee APP</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item :label="$t('questionnaire.Employeelist')" prop="userCdsids">
              <section class="user-tree">
                <div class="edp-backend-userPermitDialog-subTitle">
                  {{ $t("edpBackendRole.permitDialogSubTitle1") }}
                </div>

                <div class="edp-backend-userPermitDialog-subContent">
                  <div class="edp-backend-userPermitDialog-subContent-search">
                    <el-input
                      max="100"
                      v-model="dataUserTreeSearch"
                      :placeholder="$t('edpBackendRole.userTreeSearchPlaceholder')"
                      clearable
                    />
                  </div>
                  <el-tree
                    :props="shareDialog.defaultProps"
                    :data="shareDialog.dataUserTree"
                    show-checkbox
                    node-key="code"
                    ref="treeUserPermit"
                    :default-checked-keys="shareDialog.dataUserTreeDefault"
                    :filter-node-method="filterNode"
                    @check-change="dataUserTreeChange"
                    v-if="shareDialog.dataUserTreeShow"
                  >
                  </el-tree>
                </div>
              </section>
            </el-form-item>
          </el-form>
        </section>

        <section class="edp-backend-userPermitDialog-section">
          <div class="edp-backend-userPermitDialog-subTitle">
            {{ $t("edpBackendRole.permitDialogSubTitle2") }}
          </div>

          <div class="edp-backend-userPermitDialog-subContent">
            <div
              class="edp-backend-userPermitDialog-alSeledList"
              v-for="(item, index) in shareDialog.userPermitTreeResult"
              :key="'userPermitTreeResult' + index"
            >
              <p>{{ item.name }}</p>

              <em @click="shareItemDelete(item, index)">{{
                $t("edpBackendCommon.delete")
              }}</em>
            </div>
          </div>
        </section>
      </div>

      <div slot="footer" class="dialog-footer">
        <section class="mb4">
          {{$t('edpBackendCommon.total')}}:
          <span
            v-if="shareDialog.userPermitTreeResult && shareDialog.userPermitTreeResult.length"
            class="color-teal"
          >{{ shareDialog.userPermitTreeResult.length }}</span>
          <span v-else>--</span>
        </section>
        <section>
          <el-button @click="shareDialog.visible = false">{{
            $t("edpBackendCommon.canale")
          }}</el-button>
          <el-button type="primary" @click="shareDataSave">{{$t('questionnaire.share')}}</el-button>
        </section>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "share",
  data() {
    return {
      searchParam: {
        employee: undefined,
        touchPoint: undefined,
      },
      pageSize: 10,
      total: 0,
      pageNo: 1,
      totalPage: 0,
      publicStatusList: [
        {
          label: this.$t('questionnaireTemplate.Published'),
          value: "1",
        },
        {
          label: this.$t('questionnaireTemplate.Unpublished'),
          value: "2",
        },
      ],
      templateData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
      publish: {
        form: {
          anonymousFlag: 1,
          releaseChannels: [],
        },
        rules: {
          anonymousFlag: [
            { required: true, message: 'Please Choose Anonymous', trigger: 'change' }
          ],
          endTime: [
            { type: 'date', required: false, message: 'Please Choose Survey Closing Date', trigger: 'change' }
          ],
          releaseChannels: [
            { type: 'array', required: true, message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.TouchPoint')}`, trigger: 'change' }
          ],
        }
      },
      shareData: {
        adfsGenerateUrl: undefined,
        adfsQrcode: undefined,
        appGenerateUrl: undefined,
        appQrcode: undefined,
        h5GenerateUrl: undefined,
        h5Qrcode: undefined,
        name: undefined,
        id: undefined,
        code: undefined,
      },
      shareDialog: {
        visible: false,
        touchPoints: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        rules: {
          touchPoints: [
            { type: 'array', required: true, message: 'Please Choose Authentication', trigger: 'change' }
          ],
        },
        dataUserTree: [],
        userPermitTreeResult: [],
        dataUserTreeShow: false,
        dataUserTreeDefault: [],
        dataUserTree_selectId: "",
      },
      dataUserTreeSearch: "",
      shareRecordData: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {},
    },
    dataUserTreeSearch(val) {
      this.$refs.treeUserPermit.filter(val);
    },
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.code = this.$route.query.code;
    if (this.id) {
      this.shareData.id = this.id;
      await this.getQrcode();
    }
    if (this.code) {
      this.shareData.code = this.code;
    }
    this.fetchShareRecordList()
  },
  created() {},
  methods: {
    ...mapActions({
      templateList: "questionnaireTemplate/templateList",
      surveyView: "questionnaireTemplate/surveyView",
      publishQuestion: "questionnaireTemplate/publishQuestion",
      shareQrcode: "questionnaireTemplate/shareQrcode",
      shareRecordList: "questionnaireTemplate/shareRecordList",
      shareRecordExport: "questionnaireTemplate/shareRecordExport",
      shareUserTree: "questionnaireTemplate/shareUserTree",
      shareSurveyNotice: "questionnaireTemplate/shareSurveyNotice",
      surveyDelete: "questionnaireTemplate/surveyDelete",
      offlineQuestion: "questionnaireTemplate/offlineQuestion",
      onlineQuestion: "questionnaireTemplate/onlineQuestion",
      turnoffQuestion: "questionnaireTemplate/turnoffQuestion",
      turnonQuestion: "questionnaireTemplate/turnonQuestion",
      surveyQrcode: "questionnaireTemplate/surveyQrcode"
    }),
    async urlCopy(string) {
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(string);
      } else {
        var textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = string;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message({
        type: "success",
        message: this.$t('edpBackendCommon.actionSuccess'),
      });
    },
    async getQrcode() {
      await this.shareQrcode({id: this.id}).then(response => {
        if (response.success) {
          this.shareData = response.data;
        } else {
          this.$message({
            type: "warning",
            message: response.msg,
          });
        }
      })
    },
    fetchShareRecordList() {
      const params = {
        current: this.pageNo,
        size: this.pageSize,
        surveyCode: this.shareData.code,
        employee: this.searchParam.employee,
        touchPoint: this.searchParam.touchPoint,
      }
      this.shareRecordList(params).then(response => {
        if (response.success) {
          this.shareRecordData = response.data;
          this.total = response.total;
          this.totalPage = response.totalPage;
        } else {
          this.$message({
            type: "warning",
            message: response.msg,
          });
        }
      })
    },
    async shareExport() {
      const params = {
        current: this.pageNo,
        size: this.pageSize,
        surveyCode: this.shareData.code,
        employee: this.searchParam.employee,
        touchPoint: this.searchParam.touchPoint,
      }

      let res = await this.shareRecordExport(params);
      if (res) {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const FileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = FileUrl;
        a.download = `${this.shareData.name}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(FileUrl);
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    },
    pageTreeResultInit() {
      this.shareDialog.userPermitTreeResult = this.$refs.treeUserPermit.getCheckedNodes(
        true,
        true
      );
    },
    pageTreeInit(data) {
      data.forEach((val) => {
        this.$set(
          val,
          "name",
          val.name
            ? val.name
            : this.$i18n.locale === "en"
            ? val.englishName || val.name
            : val.name || val.englishName
        );
        this.$set(val, "code", val.code ? val.code : val.code);

        let userLsAry = [];

        if (val.userList && val.userList.length > 0) {
          val.userList.forEach((val) => {
            this.$set(val, "name", val.fullName);
            this.$set(val, "code", val.cdsid);

            if (val.selected) {
              this.shareDialog.userPermitTreeResult.push(val);
              this.shareDialog.dataUserTreeDefault.push(val.cdsid);
            }
          });

          userLsAry = JSON.parse(JSON.stringify(val.userList));
        }

        if (val.children && val.children.length > 0) {
          let childrenLsAry = JSON.parse(JSON.stringify(val.children));

          val.children = childrenLsAry.concat(userLsAry);

          this.pageTreeInit(val.children);
        } else {
          this.$set(val, "children", userLsAry);
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return (
        (data.name &&
          data.name.toLowerCase().indexOf(value.toLowerCase()) != -1) ||
        (data.cdsid && data.cdsid.indexOf(value) != -1)
      );
    },
    async permitDialogInit() {
      this.shareDialog.userPermitTreeResult = [];
      this.shareDialog.dataUserTreeDefault = [];

      let res = await this.shareUserTree();

      this.shareDialog.dataUserTree = res.data ? res.data : [];

      this.pageTreeInit(this.shareDialog.dataUserTree);

      this.shareDialog.dataUserTreeShow = true;

      this.$forceUpdate();
    },
    async openShareDialog() {
      await this.permitDialogInit();
      this.shareDialog.visible = true;
      this.shareDialog.touchPoints = [];
      this.dataUserTreeSearch = "";
    },
    async shareDataSave() {
      let seRsLs = [];

      this.shareDialog.userPermitTreeResult.forEach((val) => {
        if (val.cdsid) {
          seRsLs.push(val.cdsid);
        }
      });
      if (!this.shareDialog.touchPoints || this.shareDialog.touchPoints.length === 0) {
        this.$message({
          type: "warning",
          message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.TouchPoint')}`,
        });
        return;
      }
      if (!seRsLs || seRsLs.length === 0) {
        this.$message({
          type: "warning",
          message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.Employee')}`,
        });
        return;
      }

      let params = {
        touchPoints: this.shareDialog.touchPoints,
        code: this.shareData.code,
        userCdsids: seRsLs,
      };

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      await this.shareSurveyNotice(params).then(response => {
        if (response.success) {
          this.shareDialog.visible = false;
          this.dataUserTreeSearch = "";
          loading.close();
          this.fetchShareRecordList();
        }
      });

    },
    reshare(recode) {
      this.$confirm(`${this.$t('edpBackendCommon.confirm')} ${this.$t('questionnaire.Reshare')} ${this.$t('questionnaire.thisquestionnaire')}`, this.$t('edpBackendCommon.noticeTitle'), {
          confirmButtonText: this.$t('questionnaire.Reshare'),
          cancelButtonText: this.$t('edpBackendCommon.return'),
          center: false,
        })
        .then(async () => {
          let seRsLs = [recode.sendCdsid];

          let params = {
            touchPoints: [recode.touchPoint],
            code: recode.surveyCode,
            userCdsids: seRsLs,
          };

          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          let res = await this.shareSurveyNotice(params);

          loading.close();

          if (res.success) {
            this.$message({
              type: "success",
              message: this.$t('edpBackendCommon.actionSuccess'),
            });
            this.fetchShareRecordList();
          }
        })
        .catch(() => {});
    },
    dataUserTreeChange() {
      this.pageTreeResultInit();
    },
    shareItemDelete(data, index) {
      this.shareDialog.userPermitTreeResult.splice(index, 1);

      this.shareDialog.dataUserTreeDefault.some((val, e) => {
        if (val == data.cdsid) {
          this.shareDialog.dataUserTreeDefault.splice(e, 1);
        }
      });

      this.$refs.treeUserPermit.setChecked(data.cdsid, false, true);
    },
    backQuestionList() {
      this.$router.push({ path: 'backend-questionnaire' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/src/styles/variable.scss";

.edp-backend-content-out {
  .edp-backend-conditionSearch {
    padding-top: 0;
  }
}
.color-teal {
  color: #3E886D;
}
.color-3E886D {
  color: #3E886D;
}
.user-tree {
  height: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  &:first-child {
    border-right: 1px solid #eee;
    margin-right: 4%;

    .edp-backend-userPermitDialog-subTitle {
      padding-left: toPc(24);
    }
  }
}
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
>>> .el-card__header {
  width: 100%;
}
.table-empty {
  span.img {}
  span.text {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;
  }
  padding-top: 30px;
  padding-bottom: 60px;
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3E886D;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
